.imageStyle {
  padding: 0px;
  margin: auto;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
}

.imagelogo {
}
